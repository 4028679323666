<template>
  <div class="">
    <data-header/>
    <div class="content">

      <div class="content-left" :class="{'leftCollapse':!isCollapse}">
        <el-input placeholder="请输入内容" v-model="keyword" @keyup.enter.native="searchDown()">
          <el-button slot="append" icon="el-icon-search" @click="searchDown()"></el-button>
        </el-input>
        <div class="left-content color-42A5C6 text-16" @scroll="searchListScroll">
          <div class="left-list" :class="{'leftListCollapse':!isCollapse}" v-for="(item,index) in searchList"
               :key="index" @click="go(item)">
            <div class="margin-top-20 text-18 color-14CA65">{{ item.oldPersonName }}</div>
            <div class="margin-top-17">年龄：{{ item.age }}岁</div>
            <div class="margin-top-10">电话号码：{{ item.contract }}</div>
            <div class="margin-top-10">人员类别：{{ item.personTypeNames }}</div>
            <div class="margin-top-10">地址：{{ item.address }}</div>
          </div>
        </div>
      </div>
      <div class="content-right flex flex-wrap" @scroll="rightListScroll">
        <div class="right-list" v-for="(item,index) in rightList" :key="index">
          <div class="right-list-header">
            <div class="color-65 text-18 flex align-center">
              <div> {{ item.oldPersonName }}</div>
              <div style="color: #14CA65;margin-left: 15px" v-if="item.hasBasicData === false">({{
                  item.extFrom
                }}~{{ item.extTo }})
              </div>
            </div>
            <div v-if="item.lastAlarmState === 2" class="flex align-center">
              <div class="success-round"></div>
              <div class="text-14 color-65">已处理</div>
            </div>
            <div v-if="item.lastAlarmState === 1" class="flex align-center">
              <div class="warn-round"></div>
              <div class="text-14 color-EC5354">未处理</div>
            </div>
          </div>
          <div class="right-list-content">
            <div :id="'temperature'+index"></div>
            <div :id="'heartRate'+index"></div>
            <div :id="'breathing'+index"></div>
            <div :id="'kinesis'+index"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import dataHeader from "./header";

export default {
  components: {
    dataHeader
  },
  data() {
    return {
      keyword: "",
      searchList: [],
      searchPageNum: 1,
      searchListLoad: false,
      rightList: [],
      rightPageNum: 1,
      rightListLoad: false,
    }
  },

  computed: {
    ...mapState(["tabName"]),
    "isCollapse": {
      get() {
        return this.$store.state.isCollapse;
      },
      set(newValue) {
        this.$store.state.isCollapse = newValue;
      }
    },
  },

  mounted() {
    this.getSearchList(true);
    this.getRightList();
  },

  methods: {
    ...mapActions(['getBedList']),
    searchDown() {
      this.searchPageNum = 1;
      this.getSearchList(false);
    },
    /**
     * 获取左侧列表
     */
    getSearchList(isLoad) {
      if (this.searchListLoad === true && isLoad === true) {
        return
      }
      this.searchListLoad = true;
      this.getBedList({
        oldPersonName: this.keyword,
        pageNum: this.searchPageNum,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          if (isLoad === false) {
            this.searchPageNum = 1
            this.searchList = []
          }
          if (res.data.pageNum * res.data.pageSize < res.data.total) {
            this.searchPageNum++
          }
          if (res.data.dataList === null) {
            res.data.dataList = []
          }
          if (res.data.dataList.length === 10) {
            this.searchListLoad = false
          }
          this.searchList = this.searchList.concat(res.data.dataList)
        }
      })
    },

    /**
     * 获取右侧列表
     */
    getRightList() {
      let that = this;
      if (this.rightListLoad === true) {
        return
      }
      this.rightListLoad = true;
      this.getBedList({
        oldPersonName: "",
        pageNum: this.rightPageNum,
        pageSize: 10
      }).then(res => {
        if (res.code === 200) {
          if (res.data.pageNum * res.data.pageSize < res.data.total) {
            this.rightPageNum++
          }
          if (res.data.dataList.length === 10) {
            this.rightListLoad = false
          }
          res.data.dataList.forEach(item => {
            if (!item.hasBasicData) {
              let splitFrom = item.extFrom.split("-");
              item.extFrom = splitFrom[1] + '-' + splitFrom[2];
              let splitTo = item.extTo.split("-");
              item.extTo = splitTo[1] + '-' + splitTo[2];
            }
          })
          this.rightList = this.rightList.concat(res.data.dataList)
          this.rightList.forEach((item, index) => {
            that.$nextTick(() => {
              if (item.hasBasicData) {
                that.getChart("temperature" + index, "打鼾（%）", "#ECAF58", item.sdbPercent);
                that.getChart("heartRate" + index, "心率（bpm）", "#EC5882", item.heartbeatRates);
                that.getChart("breathing" + index, "呼吸（次/分）", "#7EEC58", item.breathingRates);
                that.getChart("kinesis" + index, "体动（次）", "#B258EC", item.bodyMovementNumbers);
              } else {
                that.getChartBar("temperature" + index, "活动次数", "#ECAF58", item.extActiveCount);
                that.getChartBar("heartRate" + index, "烟感报警次数", "#EC5882", item.extSmokeCount);
                that.getChartBar("breathing" + index, "水浸报警次数", "#7EEC58", item.extWaterCount);
                that.getChartBar("kinesis" + index, "燃气报警次数", "#B258EC", item.extGasCount);
              }
            })
          })
        }
      })
    },

    /**
     * 左侧搜索滚动到底部
     */
    searchListScroll() {
      this.$nextTick(() => {
        const el = document.querySelector('.left-content');
        const offsetHeight = el.offsetHeight;
        el.onscroll = () => {
          const scrollTop = el.scrollTop;
          const scrollHeight = el.scrollHeight;
          if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
            this.getSearchList(true)
          }
        };
      });
    },
    /**
     * 右侧搜索滚动到底部
     */
    rightListScroll() {
      this.$nextTick(() => {
        const el = document.querySelector('.content-right');
        const offsetHeight = el.offsetHeight;
        el.onscroll = () => {
          const scrollTop = el.scrollTop;
          const scrollHeight = el.scrollHeight;
          if ((offsetHeight + scrollTop) - scrollHeight >= -1) {
            this.getRightList();
          }
        };
      });
    },

    go(item) {
      if (item.hasBasicData) {
        this.$router.push({path: "/smartElderlyPlat/weekReport", query: {id: item.oldPersonId}})
      }
    },

    /**
     * 获取曲线图
     * @param idName
     * @param yName
     * @param lineColor
     * @param data
     */
    getChart(idName, yName, lineColor, data) {
      let myChart = this.$echarts.init(document.getElementById(idName))
      myChart.setOption({
        grid: {
          left: 10,
          right: 20,
          top: 40,
          bottom: 10,
          containLabel: true
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2574BF"
            }
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: yName,
          min: Math.min.apply(null, data),
          max: Math.max.apply(null, data),
          nameLocation: "end",
          nameTextStyle: {
            color: '#65C6E7',
            fontSize: 13,
            width: 200,
            align: 'left',
            padding: [0, 0, 0, -35]
          },
          splitNumber: 5,
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 14
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2574BF"
            }
          }
        },
        series: [
          {
            data: data,
            type: 'line',
            smooth: 'true',
            symbol: "none",
            lineStyle: {
              color: lineColor
            }
          }
        ]
      })
    },


    /**
     * 获取曲线图
     * @param idName
     * @param yName
     * @param lineColor
     * @param data
     */
    getChartBar(idName, yName, lineColor, data) {
      let myChart = this.$echarts.init(document.getElementById(idName))
      myChart.setOption({
        grid: {
          left: 50,
          right: 20,
          top: 40,
          bottom: 10,
          containLabel: false
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2574BF"
            }
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: yName,
          min: 0,
          max: Math.max.apply(null, data),
          nameLocation: "end",
          nameTextStyle: {
            color: '#65C6E7',
            fontSize: 13,
            width: 200,
            align: 'left',
            padding: [0, 0, 0, -35]
          },
          splitNumber: 5,
          minInterval: 1,
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 14
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2574BF"
            }
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            smooth: 'true',
            symbol: "none",
            barWidth: 10,
            itemStyle: {
              color: lineColor
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: start;
  min-height: calc(100%);
  background-color: #191a23;
  padding-bottom: 3.08rem;
}

.content-left {
  width: 50.05rem;
  height: 115.17rem;
  background-image: url("../../../assets/images/smartElderly/老人列表-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 1.87rem 0 0 0.44rem;
}

.leftCollapse {
  width: calc(50.05rem + 256px);
}

.leftCollapse /deep/ .el-input {
  margin-left: 5.84rem !important;
  width: calc(39.77rem + 256px) !important;
}


.content-left /deep/ .el-input {
  margin-top: 7.81rem;
  margin-left: 4.84rem;
  width: 41.47rem;
  height: 4.6rem;
  border-radius: 8px 0 0 8px;
}

.content-left /deep/ .el-input-group__append {
  background: #001428;
  height: 4.4rem;
  border: 1px solid #3C62C0;
  border-left: none;
}

.content-left /deep/ .el-input-group__append .el-icon-search {
  font-size: 2.2rem;
  color: #3C62C0;
}


/deep/ input, /deep/ input:hover, /deep/ input:focus {
  background: #001428;
  border: 1px solid #3C62C0;
  border-radius: 8px 0 0 8px;
  color: #65C6E7;
  height: 4.6rem;
  line-height: 4.6rem;
}

/deep/ input::-moz-placeholder {
  color: #316577;
  font-size: 1.76rem;
}

/deep/ input::-ms-input-placeholder {
  color: #316577;
  font-size: 1.76rem;
}

/deep/ input::-webkit-input-placeholder {
  color: #316577;
  font-size: 1.76rem;
}


.left-content {
  max-height: 95.7rem;
  overflow: hidden;
  overflow-y: auto;
}

.left-list {
  width: 37.4rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 4px;
  margin-top: 2.09rem;
  margin-left: 4.84rem;
  padding: 0 2.31rem 1.98rem 1.76rem;
}

.leftListCollapse {
  width: calc(35.4rem + 256px) !important;
  margin-left: 5.84rem !important;
}

.content-right {
  width: 131.45rem;
  max-height: 116.6rem;
  overflow: hidden;
  overflow-y: auto;
}

.right-list {
  /*width: 62rem;*/
  width: calc(50% - 0.6rem);
  height: 56.65rem;
  background-image: url("../../../assets/images/smartElderly/老人信息-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 2.2rem;
  margin-left: 0.55rem;
}

.right-list:nth-child(2) ~ div {
  margin-top: 3.85rem;
}

.right-list-header {
  margin-top: 4.62rem;
  margin-left: 5.28rem;
  /*width: 53.68rem;*/
  width: calc(100% - 10rem);
  height: 6.38rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #004586;
}

.success-round, .warn-round {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  margin-right: 1.1rem;
}

.success-round {
  background: #14CA65;
}

.warn-round {
  background: #EC5354;
}

.right-list-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 57.31rem;
  padding: 0 0 0 3.08rem;
}

.right-list-content > div {
  margin-top: 3.41rem;
  width: 28.27rem;
  height: 15.4rem;
}
</style>
<style>
.el-main {
  border: none !important;
}
</style>
