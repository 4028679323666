<template>
  <div class="data-bg">
    <div class="data-bg-image"></div>
    <div class="data-bg-content">
      <div style="width: 200px"><i class="el-icon-s-operation" @click="showMenu"></i></div>
      <div class="data-title">{{ mainPageTitle }}</div>
      <div class="data-date" style="width: 200px">
        <img src="../../../assets/images/smartElderly/shijian.png" width="20px" height="20px">
        <div>{{ nowDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "header",
  data() {
    return {
      nowDate: '',
      mainPageTitle: '',
    }
  },
  computed: {
    ...mapGetters(['userInfos'])
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    this.timer = setInterval(this.updateTime, 1000)
  },
  methods: {
    ...mapActions(['loginUserInfo']),
    showMenu() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse
    },
    updateTime() {
      this.nowDate = this.$dayjs().format('YYYY-MM-DD') + ' ' + this.$dayjs().format('HH:mm')
    },
    getUserInfo() {
      let server = 'loginUserInfo'
      this[server]().then((res) => {
        if (res.code == 200) {
          this.mainPageTitle = res.data.mainPageTitle
        }
      })
    },
  }
}
</script>

<style scoped>
.data-bg {
  width: 100%;
  height: 108px;
  line-height: 108px;
  background: #0C131D;
  position: relative;

}

.data-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108px;
  background-image: url("../../../assets/images/smartElderly/head.png");
  background-size: 100% 100%;
}

.data-bg-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.data-title {
  font-size: 38px;
  font-weight: 400;
  line-height: 108px;
  background: linear-gradient(0deg, #04A8F0 0%, #1EAED2 50.2685546875%, #4177ED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.el-icon-s-operation {
  font-size: 30px;
  color: #FFFFFF;
}

.data-date {
  display: flex;
  align-items: center;
}

.data-date > div {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #52FFFF;
}
</style>
